import {Injectable} from '@angular/core';
import {TokenRenewalDto, TokenRenewalResultDto, UserDto} from '@api/api.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthStorageService {

  private readonly storageKey = environment.auth.storageKey;
  private storage: Storage;

  constructor() {
    this.storage = localStorage.getItem(this.storageKey) ? localStorage : sessionStorage;
  }

  saveToken(tokenData: TokenRenewalResultDto, keepLoggedIn?: boolean) {
    if (keepLoggedIn) {
      this.storage = localStorage;
    }

    this.storage.setItem(this.storageKey, JSON.stringify(tokenData));
  }

  getToken(): TokenRenewalResultDto {
    if (this.storage.getItem(this.storageKey)) {
      return JSON.parse(this.storage.getItem(this.storageKey)) || null;
    }

    return null;
  }

  getTokenForApi(): string {
    const data = this.getToken();
    if (data) {
      return data.token || null;
    }

    return null;
  }

  getTokenForRenew(): TokenRenewalDto {
    const data = this.getToken();
    if (data) {
      return {
        username: data.user.username,
        refreshToken: data.refreshToken
      };
    }

    return null;
  }

  getTokenForLogout() {
    const data = this.getToken();
    if (data) {
      return {
        refreshToken: data.refreshToken || null
      };
    }

    return null;
  }

  getCurrentUser(): UserDto {
    const data = this.getToken();
    if (data) {
      return data.user || null;
    }

    return null;
  }

  clear() {
    this.storage.removeItem(this.storageKey);
  }
}
