import { Injectable } from '@angular/core';
import {
  ApiException, ApiResponseOfContactBaseInfoDto,
  ApiResponseOfContactDetailDto,
  ApiResponseOfIEnumerableOfContactListDto, ContactDetailDto,
  IContactApiService
} from '@api/api.service';
import { Observable, of, throwError } from 'rxjs';
import { CONTACTS } from '@app/mock/data/contacts';

export class ContactApiMockService implements IContactApiService {

  private items: ContactDetailDto[] = CONTACTS;

  constructor() { }

  getContacts(skip?: number, take?: number, orderby?: string, searchString?: string): Observable<ApiResponseOfIEnumerableOfContactListDto> {
    return of({
      data: this.items.slice(skip, skip + take),
      maxCount: this.items.length
    });
  }

  getContactDetails(contactId: string): Observable<ApiResponseOfContactDetailDto> {
    const contact = CONTACTS.find(c => c.id === contactId);
    if (!contact) {
      return throwError(new ApiException('NOT FOUND', 404, '', {}, null));
    }
    return of({
      data: contact
    });
  }

  getContactBaseInformation(): Observable<ApiResponseOfContactBaseInfoDto> {
    return this.getContactDetails(CONTACTS[0].id);
  }

}
