import {
  ApiResponseOfIEnumerableOfContractDto,
  ApiResponseOfIEnumerableOfContractListDto,
  ApiSortDirection,
  IContactContractApiService
} from '@api/api.service';
import { Observable, of } from 'rxjs';

export class ContactContractApiMockService implements IContactContractApiService {
  getAllContactContractsOfCustomer(skip?: number, take?: number, searchString?: string, orderby?: string,
                                   sortDirection?: ApiSortDirection): Observable<ApiResponseOfIEnumerableOfContractListDto> {
    return of({
      success: true,
      data: [],
      maxCount: 0
    });
  }

  getContactContracts(contactId: string , skip?: number, take?: number,
                      orderby?: string, searchString?: string): Observable<ApiResponseOfIEnumerableOfContractDto> {
    return of({
      success: true,
      data: [],
      maxCount: 0
    });
  }

}
