import { Provider } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '@app/http-interceptors/auth.interceptor';
import { RenewInterceptor } from '@app/http-interceptors/renew.interceptor';

export const INTERCEPTORS: Provider[] = [
  // the order is import, because the renew interceptor expects that next.handle sets always the newest token
  { provide: HTTP_INTERCEPTORS, useClass: RenewInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
