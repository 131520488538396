import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import { MenuItem } from '@core/navigation/models/menu-item';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  mainMenuItems$: Observable<MenuItem[]>;

  @Output()
  navigated = new EventEmitter();

  constructor(private navService: NavigationService) { }

  ngOnInit() {
    this.mainMenuItems$ = this.navService.getMainMenuItems();
  }

}
