import { NgModule } from '@angular/core';
import {
  AuthApiService, ContactApiService, ContactContractApiService,
  ContactOfferApiService,
  ContractApiService,
  CurrentUserInfoApiService, CustomerApiService,
  NewsApiService, RequestLoginApiService,
  ThemingApiService,
  ThemingImageApiService
} from '@api/api.service';
import { AuthApiMockService } from '@app/mock/services/auth.api-mock.service';
import { CurrentUserInfoApiMockService } from '@app/mock/services/current-user-info.api-mock.service';
import { ContactOfferApiMockService } from '@app/mock/services/contact-offer.api-mock.service';
import { ThemingApiMockService } from '@app/mock/services/theming.api-mock.service';
import { ThemingImageApiMockService } from '@app/mock/services/theming-image.api-mock.service';
import { ContractApiMockService } from '@app/mock/services/contract.api-mock.service';
import { NewsApiMockService } from '@app/mock/services/news.api-mock.service';
import { CustomerApiMockService } from '@app/mock/services/customer.api-mock.service';
import { ContactContractApiMockService } from '@app/mock/services/contact-contract.api-mock.service';
import { RequestLoginApiMockService } from '@app/mock/services/request-login.api-mock.service';
import { ContactApiMockService } from '@app/mock/services/contact.api-mock.service';

@NgModule({
  providers: [
    { provide: ThemingApiService, useClass: ThemingApiMockService },
    { provide: ThemingImageApiService, useClass: ThemingImageApiMockService },
    { provide: AuthApiService, useClass: AuthApiMockService },
    { provide: CurrentUserInfoApiService, useClass: CurrentUserInfoApiMockService },
    { provide: ContactApiService, useClass: ContactApiMockService },
    { provide: ContactOfferApiService, useClass: ContactOfferApiMockService },
    { provide: NewsApiService, useClass: NewsApiMockService },
    { provide: ContractApiService, useClass: ContractApiMockService },
    { provide: CustomerApiService, useClass: CustomerApiMockService },
    { provide: ContactContractApiService, useClass: ContactContractApiMockService },
    { provide: RequestLoginApiService, useClass: RequestLoginApiMockService }
  ]
})
export class MockModule { }
