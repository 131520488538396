import { ApiResponseOfExtendedUserInfoDto, ICurrentUserInfoApiService, UserType } from '@api/api.service';
import { Observable, of } from 'rxjs';

export class CurrentUserInfoApiMockService implements ICurrentUserInfoApiService {
  getExtendedUserInfo(): Observable<ApiResponseOfExtendedUserInfoDto> {
    return of({
      success: true,
      data: {
        userName: 'Admin',
        userType: UserType.Customer
      }
    } as ApiResponseOfExtendedUserInfoDto);
  }
}
