<mat-card class="mat-elevation-z4">
  <ng-container *ngIf="userInfo$ | async as context; else loading">
    <div mat-card-avatar>
      <ng-container *ngIf="context.userDisplayName | slice:0:1 as initial">{{initial}}</ng-container>
    </div>
    <mat-card-header>
      <mat-card-title *ngIf="context.userDisplayName">{{ context.userDisplayName }}</mat-card-title>
      <mat-card-subtitle *ngIf="context.userDisplayName">{{ context.userName }}</mat-card-subtitle>
      <mat-card-title *ngIf="!context.userDisplayName">{{ context.userName }}</mat-card-title>
    </mat-card-header>
  </ng-container>
  <ng-template #loading>
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </ng-template>

  <mat-card-actions>
    <p class="mat-error" *ngIf="logoutError">{{ 'AUTHENTICATION.ACTIONS.LOGOUT.ERROR' | translate }}</p>
    <button mat-stroked-button (click)="logout()" [disabled]="logoutPending" class="loading-button">
      <span>{{ 'AUTHENTICATION.ACTIONS.LOGOUT.LABEL' | translate }}</span>
      <span class="loading-wrapper">
        <mat-spinner *ngIf="logoutPending" diameter="16" strokeWidth="2"></mat-spinner>
      </span>
    </button>
  </mat-card-actions>
</mat-card>
