import {ApiResponseOfThemeInfoDto, IThemingApiService} from '@api/api.service';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class ThemingApiMockService implements IThemingApiService {
  getTheme(companySubdomain?: string): Observable<ApiResponseOfThemeInfoDto> {
    return of({
      data: {
        primaryColor: '#07775f',
        primaryContrastColor: '#fff',
        accentColor: '#c77c1e',
        accentContrastColor: '#000'
      },
      success: true
    } as ApiResponseOfThemeInfoDto);
  }

}
