import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApiModule } from '@api/api.module';
import { CoreModule } from '@core/core.module';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { INTERCEPTORS } from '@app/http-interceptors';
import { SharedModule } from '@shared/shared.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { environment } from '../environments/environment';
import { MockModule } from '@app/mock/mock.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlProvider } from '@app/data/mat-paginator-intl-provider';
import { ANT_UI_DEFAULT_BUTTON_CONFIG } from '@antony/ng-ui';
import { APP_ANT_BUTTON_CONFIG } from '@app/data/app-ant-button-config';
import { AntNotificationModule } from '@antony/core';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export function createHttpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

const environmentSpecificModules = [];
if (environment.mock) {
  environmentSpecificModules.push(MockModule);
}

@NgModule({
  providers: [
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: MatPaginatorIntl, useExisting: MatPaginatorIntlProvider },
    { provide: ANT_UI_DEFAULT_BUTTON_CONFIG, useValue: APP_ANT_BUTTON_CONFIG },
    ...INTERCEPTORS
  ],
  imports: [
    AntNotificationModule.forRoot({
      lowPriorityConfig: {
        duration: 3500
      }
    }),
    BrowserModule,
    BrowserAnimationsModule,

    ScrollingModule,

    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createHttpTranslateLoader,
        deps: [HttpClient]
      }
    }),

    ApiModule,
    CoreModule,
    SharedModule,
    ...environmentSpecificModules,

    AppRoutingModule
  ],
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
