import { Component, Input } from '@angular/core';
import { ThemingService } from '@api/services/theming.service';
import { CurrentUserService } from '@app/authentication/services/current-user.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-img-company',
  templateUrl: './img-company.component.html',
  styleUrls: ['./img-company.component.scss']
})
export class ImgCompanyComponent {

  @Input()
  type: 'primary'|'secondary' = 'primary';

  @Input()
  alt: string = null;

  private customerDisplayName: string;

  constructor(private api: ThemingService) {
    this.api.getTheme().pipe(
      map(data => data?.companyName)
    ).subscribe((customerDisplayName) => this.customerDisplayName = customerDisplayName);
  }

  getImageUrl(type: 'primary' | 'secondary'): string {
    return this.api.getImageUrl(type);
  }

  getAlt(): string {
    return this.alt || this.customerDisplayName;
  }
}
