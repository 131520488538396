import { Injectable } from '@angular/core';
import {
  ApiResponse,
  ApiResponseOfLoginResultDto,
  ApiResponseOfTokenRenewalResultDto,
  AuthApiService,
  LoginDto,
  LogoutDto, RequestLoginApiService,
  TokenRenewalDto
} from '@api/api.service';
import { NEVER, Observable } from 'rxjs';
import { RegisterDTO } from '@app/authentication/models/register';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private api: AuthApiService,
              private registerApi: RequestLoginApiService) {
  }

  login(dto: LoginDto): Observable<ApiResponseOfLoginResultDto> {
    return this.api.login(dto);
  }

  logout(dto: LogoutDto): Observable<ApiResponse> {
    return this.api.logout(dto);
  }

  renewToken(dto: TokenRenewalDto): Observable<ApiResponseOfTokenRenewalResultDto> {
    return this.api.renewToken(dto);
  }

  register(dto: RegisterDTO): Observable<void> {
    return this.registerApi.requestNewLoginCredentials(dto);
  }
}
