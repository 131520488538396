import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeService } from '@core/theming/services/theme.service';
import { ThemingService } from '@api/services/theming.service';
import { map } from 'rxjs/operators';


@NgModule({
  providers: [ThemeService],
  imports: [
    CommonModule
  ],
  declarations: [],
  exports: []
})
export class ThemingModule {
  constructor(private theme: ThemeService,
              private api: ThemingService) {
    this.initializeTheme();
  }

  private initializeTheme() {
    this.api.getTheme().pipe(
      map((theme) => this.theme.setTheme(
        theme.primaryColor,
        theme.primaryContrastColor,
        theme.accentColor,
        theme.accentContrastColor,
      ))
    ).subscribe();
  }
}
