import { AttachmentGroup } from '@core/models/attachment-group';
import { FILES } from '@app/mock/data/files';

export const NEWS: AttachmentGroup[] = [
  {
    name: 'Presse-Mitteilungen',
    attachments: FILES
  },
  {
    name: 'Presse-Mitteilungen 2',
    attachments: FILES
  },
  {
    name: 'Presse-Mitteilungen 3',
    attachments: []
  },
  {
    name: 'Presse-Mitteilungen 4',
    attachments: []
  },
  {
    name: 'Presse-Mitteilungen 5',
    attachments: FILES
  },
  {
    name: 'Presse-Mitteilungen',
    attachments: []
  },
  {
    name: 'Presse-Mitteilungen',
    attachments: []
  }
];
