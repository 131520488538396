import { CurrentUserInfoApiService, ExtendedUserInfoDto } from '@api/api.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserInfoService {

  constructor(private api: CurrentUserInfoApiService) {
  }

  getExtendedUserInfo(): Observable<ExtendedUserInfoDto> {
    return this.api.getExtendedUserInfo().pipe(
      map(res => res.data)
    );
  }
}
