import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from '@app/authentication/services/current-user.service';
import { UserType } from '@api/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private currentUser: CurrentUserService,
              private router: Router) { }

  ngOnInit(): void {
    this.currentUser.get().subscribe(info => {
      if (info?.userType === UserType.Contact) {
        this.router.navigate(['offers']);
      } else {
        this.router.navigate(['customer']);
      }
    });
  }

}
