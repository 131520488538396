import { ApiResponseOfCustomerDto, ICustomerApiService } from '@api/api.service';
import { Observable, of } from 'rxjs';

export class CustomerApiMockService implements ICustomerApiService {
  getCustomerInfo(): Observable<ApiResponseOfCustomerDto> {
    return of({
      success: true,
      data: {
        displayName: 'Test GmbH',
        address: 'Musterstr. 1',
        zip: '12345',
        city: 'Musterstadt',
        region: 'Nord-Rhein-Westfalen',
        country: 'Deutschland',
        phoneNumber: '+49 123 456789',
        fax: '+49 123 456780',
        email: 'test@muster.de',
        webUrl: 'https://muster.de',
      }
    });
  }

  getCustomerContactInfo(): Observable<ApiResponseOfCustomerDto> {
    return undefined;
  }
}
