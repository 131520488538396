import {
  ApiResponse,
  ApiResponseOfIEnumerableOfPermissionDto,
  ApiResponseOfLoginResultDto,
  ApiResponseOfTokenRenewalResultDto,
  IAuthApiService,
  LoginDto,
  LogoutDto,
  TokenRenewalDto
} from '@api/api.service';
import { Observable, of } from 'rxjs';

export class AuthApiMockService implements IAuthApiService {
  login(dto: LoginDto): Observable<ApiResponseOfLoginResultDto> {
    return of({
      success: true,
      message: null,
      count: null,
      maxCount: null,
      data: {
        isUserDisabled: false,
        tokenData: {
          token: 'ABCTOKEN',
          refreshToken: 'ABCTOKENREFRESH',
          validUntil: '3996-10-15T00:05:32.000Z',
          user: {
            username: 'Admin',
            firstname: 'Admin',
            lastname: 'istrator',
            id: 1,
            isEnabled: true,
            permissions: []
          }
        }
      }
    });
  }

  logout(dto: LogoutDto): Observable<ApiResponse> {
    return of({
      success: true
    });
  }

  renewToken(dto: TokenRenewalDto): Observable<ApiResponseOfTokenRenewalResultDto> {
    return of({
      success: true,
      message: null,
      count: null,
      maxCount: null,
      data: {
        isUserDisabled: false,
        tokenData: {
          token: 'ABCTOKEN',
          refreshToken: 'ABCTOKENREFRESH',
          validUntil: '3996-10-15T00:05:32.000Z',
          user: {
            username: 'Admin',
            firstname: 'Admin',
            lastname: 'istrator',
            id: 1,
            isEnabled: true,
            permissions: []
          }
        }
      }
    } as ApiResponseOfTokenRenewalResultDto);
  }

  getPermissions(): Observable<ApiResponseOfIEnumerableOfPermissionDto> {
    return of(null);
  }

}
