import {Injectable, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {Subscription} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MatPaginatorIntlProvider extends MatPaginatorIntl implements OnDestroy {

  private translationSubscription: Subscription;

  constructor(private translateService: TranslateService) {
    super();
    this.initializeTranslation();
  }

  itemsPerPageLabel: string;
  nextPageLabel: string;
  previousPageLabel: string;
  firstPageLabel: string;
  lastPageLabel: string;
  ofLabel: string;

  getRangeLabel = (pageIndex: number, pageSize: number, length: number): string => {
    const firstItem = (pageIndex * pageSize) + 1;
    let lastItem = (pageIndex + 1) * pageSize;

    if (lastItem > length) {
      lastItem = length;
    }
    return `${ firstItem } - ${ lastItem } ${ this.ofLabel } ${ length }`;
  }

  ngOnDestroy(): void {
    if (this.translationSubscription) {
      this.translationSubscription.unsubscribe();
    }
  }

  private initializeTranslation() {
    this.translationSubscription = this.translateService.get([
      'ANT.PAGINATOR.FIRST_PAGE',
      'ANT.PAGINATOR.LAST_PAGE',
      'ANT.PAGINATOR.NEXT_PAGE',
      'ANT.PAGINATOR.PREVIOUS_PAGE',
      'ANT.PAGINATOR.PAGE_SIZE',
      'ANT.PAGINATOR.OF',
    ]).subscribe(translations => {
      this.firstPageLabel = translations['ANT.PAGINATOR.FIRST_PAGE'];
      this.lastPageLabel = translations['ANT.PAGINATOR.LAST_PAGE'];
      this.nextPageLabel = translations['ANT.PAGINATOR.NEXT_PAGE'];
      this.previousPageLabel = translations['ANT.PAGINATOR.PREVIOUS_PAGE'];
      this.itemsPerPageLabel = translations['ANT.PAGINATOR.PAGE_SIZE'];
      this.ofLabel = translations['ANT.PAGINATOR.OF'];
    });
  }
}
