import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route, Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentUserInfoService } from '@api/services/current-user-info.service';
import { filter, map, take } from 'rxjs/operators';
import { UserType } from '@api/api.service';
import { CurrentUserService } from '@app/authentication/services/current-user.service';

@Injectable({
  providedIn: 'root'
})
export class IsContactGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private service: CurrentUserService,
              private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.isContact().pipe(
      map(isContact => {
        if (isContact) {
          return true;
        }

        return this.router.createUrlTree(['customer']);
      })
    );
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.isContact().pipe(
      map(isContact => {
        if (isContact) {
          return true;
        }

        return this.router.createUrlTree(['customer']);
      })
    );
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> {
    return this.isContact();
  }

  private isContact(): Observable<boolean> {
    return this.service.get().pipe(
      filter(info => !!info),
      take(1),
      map(info => info.userType === UserType.Contact)
    );
  }
}
