import { Injectable } from '@angular/core';
import { ExtendedUserInfoDto } from '@api/api.service';
import { CurrentUserInfoService } from '@api/services/current-user-info.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {

  private data$: Observable<ExtendedUserInfoDto>;
  private data: ExtendedUserInfoDto = null;

  constructor(private api: CurrentUserInfoService) {
    this.init();
  }

  get(): Observable<ExtendedUserInfoDto> {
    return this.data$;
  }

  getInstant(): ExtendedUserInfoDto {
    return this.data;
  }

  private init() {
    this.data$ = this.api.getExtendedUserInfo().pipe(
      shareReplay(),
      tap(userInfo => this.data = userInfo)
    );
  }
}
