import { Component, Input, OnInit } from '@angular/core';
import { PageError } from '@core/models/page-error';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  @Input()
  type: PageError;

  types = PageError;

  constructor() { }

  ngOnInit(): void {
  }

}
