import {ApiResponseOfThemeInfoDto, FileResponse, IThemingApiService, IThemingImageApiService} from '@api/api.service';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class ThemingImageApiMockService implements IThemingImageApiService {

  getImage(companySubdomain?: string, imageType?: string): Observable<FileResponse> {
    return of(null);
  }

}
