import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, first, flatMap, shareReplay, tap } from 'rxjs/operators';
import { AuthService } from '@app/authentication/services/auth.service';

@Injectable()
export class RenewInterceptor implements HttpInterceptor {
  private currentlyRenewing = false;
  private renewRequest: Observable<boolean>;
  private readonly urlsToIgnore: string[] = [
    '/auth'
  ];

  constructor(private authService: AuthService) { }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next.handle(req).pipe(
      catchError((error: any, caught: Observable<HttpEvent<T>>) => {
        if (!this.shouldHandleRequest(req) || !this.shouldHandleError(error)) {
          throw error;
        }
        this.createRenewRequestIfNecessary();
        return this.renewRequest.pipe(
          flatMap(() => next.handle(req))
        );
      })
    );
  }

  private shouldHandleRequest<T>(req: HttpRequest<T>): boolean {
    return this.urlsToIgnore.every(urlToIgnore => !this.includesCaseInsensitive(req.url, urlToIgnore));
  }

  private shouldHandleError(error: any): boolean {
    return (error instanceof HttpErrorResponse) && error.status === 401;
  }

  private includesCaseInsensitive(target: string, needle: string): boolean {
    return target.toLowerCase().includes(needle.toLowerCase());
  }

  private createRenewRequestIfNecessary(): void {
    if (!this.isCurrentlyRenewing()) {
      this.createRenewRequest();
    }
  }

  private isCurrentlyRenewing(): boolean {
    return this.currentlyRenewing && !!this.renewRequest;
  }

  private createRenewRequest(): void {
    this.currentlyRenewing = true;
    this.renewRequest = this.authService.renew().pipe(
      first(),
      shareReplay(),
      tap(x => {
        this.currentlyRenewing = false;
        this.renewRequest = null;
      })
    );

  }
}
