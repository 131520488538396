import { MenuItem } from './models/menu-item';

const SHARED_MAIN_MENU_ITEMS: MenuItem[] = [
  { icon: 'NEWS.ICON', label: 'NEWS.TITLE', path: '/news'  },
  { icon: 'ABOUT.ICON', label: 'ABOUT.TITLE', path: '/about'  },
];

export const CONTACT_MAIN_MENU_ITEMS: MenuItem[] = [
  { icon: 'OFFER.OVERVIEW.ICON', label: 'OFFER.OVERVIEW.TITLE', path: '/offers'  },
  { icon: 'CONTACT.ME.ICON', label: 'CONTACT.ME.TITLE', path: '/contacts/me'  },
  { icon: 'CONTRACT.MY.ICON', label: 'CONTRACT.MY.TITLE', path: '/contracts/my'  },
  ...SHARED_MAIN_MENU_ITEMS
];
export const CUSTOMER_MAIN_MENU_ITEMS: MenuItem[] = [
  { icon: 'CUSTOMER.ICON', label: 'CUSTOMER.TITLE', path: '/customer'  },
  { icon: 'CONTRACT.CONTACT.ICON', label: 'CONTRACT.CONTACT.TITLE', path: '/contracts/contact'  },
  { icon: 'CONTACT.ICON', label: 'CONTACT.TITLE', path: '/contacts'  },
  ...SHARED_MAIN_MENU_ITEMS
];
