import {
  ApiResponseOfIEnumerableOfAttachmentCategoryDto,
  ApiSortDirection,
  FileResponse,
  INewsApiService,
  OneTimeAttachmentInfoDto
} from '@api/api.service';
import { NEVER, Observable, of } from 'rxjs';
import { NEWS } from '@app/mock/data/news';

export class NewsApiMockService implements INewsApiService {
  getNews(skip?: number, take?: number, orderby?: string,
          searchTerm?: string): Observable<ApiResponseOfIEnumerableOfAttachmentCategoryDto> {
    return of({
      success: true,
      maxCount: NEWS.length,
      data: NEWS.slice(skip, skip + skip + take)
    });
  }

  getOneTimeAttachmentId(attachmentId: string): Observable<OneTimeAttachmentInfoDto> {
    return NEVER;
  }

  getAttachment(onetimeAttachmentId: string): Observable<FileResponse> {
    return NEVER;
  }

  getNewsMessages(skip?: number, take?: number, searchTerm?: string, sortName?: string,
                  sortDirection?: ApiSortDirection): Observable<ApiResponseOfIEnumerableOfAttachmentCategoryDto> {
    return NEVER;
  }

}
