import {Injectable} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {Breadcrumb} from '@core/navigation/models/breadcrumb';
import {filter, map, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private readonly breadcrumbs$: BehaviorSubject<Observable<Breadcrumb>[]> = new BehaviorSubject([]);
  private readonly currentTitle$: Observable<string>;

  constructor() {
    // this maps the label of the latest breadcrumb to the title
    this.currentTitle$ = this.breadcrumbs$.pipe(
      switchMap(bcs => bcs.length ? bcs[bcs.length - 1] : of({ label: 'APP.TITLE' })),
      filter(bc => bc !== null && bc !== undefined),
      map((bc: Breadcrumb): string => bc.label)
    );
  }

  getBreadcrumbs(): Observable<Breadcrumb[]> {
    return this.breadcrumbs$.pipe(
      // this maps an array of observables to and observable of an array
      // if a single observable emits, the emitted array updates
      switchMap(items => combineLatest(items))
    );
  }

  getCurrentTitle(): Observable<string> {
    return this.currentTitle$;
  }

  addBreadcrumb(bc: Observable<Breadcrumb>) {
    this.breadcrumbs$.next([
      ...this.breadcrumbs$.value,
      bc
    ]);
  }

  popBreadcrumb() {
    const value  = this.breadcrumbs$.value;
    value.pop();
    this.breadcrumbs$.next(value);
  }

  clearBreadcrumbs() {
    this.breadcrumbs$.next([]);
  }
}
