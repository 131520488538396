import { Inject, Injectable, Optional } from '@angular/core';
import { API_BASE_URL, ThemeInfoDto, ThemingApiService } from '@api/api.service';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ThemingService {

  private readonly subdomain: string;
  private readonly baseUrl: string;
  private hasThemeLoaded = false;
  private theme$ = new ReplaySubject(1);

  constructor(private api: ThemingApiService,
              @Optional() @Inject(API_BASE_URL) baseUrl) {
    this.subdomain = this.getSubdomain();
    this.baseUrl = baseUrl ? baseUrl : 'https://localhost:5001';
  }

  getImageUrl(imageType?: 'primary' | 'secondary'): string {
    return `${this.baseUrl}/api/ThemingImage?companySubdomain=${this.subdomain}&imageType=${imageType}`;
  }

  getTheme(): Observable<ThemeInfoDto> {
    if (!this.hasThemeLoaded) {
      this.loadTheme();
    }

    return this.theme$.asObservable();
  }

  private loadTheme() {
    this.hasThemeLoaded = true;
    return this.api.getTheme(this.subdomain).pipe(
      map(res => res.data)
    ).subscribe(theme => this.theme$.next(theme));
  }

  private getSubdomain() {
    const domain = window.location.hostname;
    if (domain.indexOf('.') < 0 || domain.split('.')[0] === 'www') {
      return '';
    } else {
      return domain.split('.')[0];
    }
  }
}
