import { Component, OnInit } from '@angular/core';
import { PageError } from '@core/models/page-error';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  errorType = PageError.NOT_FOUND;

  constructor() { }

  ngOnInit(): void {
  }

}
