import {AntUiConfig} from '@antony/ng-ui';
import {TranslateService} from '@ngx-translate/core';

export const getAntUiConfig = (ts: TranslateService): AntUiConfig => ({
  labels: {
    noResults: ts.instant('ANT.UI.LABELS.NO_RESULTS'),
    displayedColumns: ts.instant('ANT.UI.LABELS.DISPLAYED_COLUMNS'),
    selectColumn: ts.instant('ANT.UI.LABELS.SELECT_COLUMN'),
  },
  actions: {
    showMore: {
      icon: ts.instant('APP.ACTIONS.SHOW_MORE.ICON'),
      label: ts.instant('APP.ACTIONS.SHOW_MORE.LABEL'),
    },
    showLess: {
      icon: ts.instant('APP.ACTIONS.SHOW_LESS.ICON'),
      label: ts.instant('APP.ACTIONS.SHOW_LESS.LABEL'),
    },
    filter: {
      icon: ts.instant('APP.ACTIONS.FILTER.ICON'),
      label: ts.instant('APP.ACTIONS.FILTER.LABEL'),
    },
  }
});
