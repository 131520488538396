import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {forkJoin, Observable} from 'rxjs';
import {Breadcrumb} from '../models/breadcrumb';
import {Title} from '@angular/platform-browser';
import {distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {BreadcrumbService} from '@core/navigation/services/breadcrumb.service';
import { AuthService } from '@app/authentication/services/auth.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  @ViewChild('drawer', { static: true }) drawer: MatDrawer;
  breadcrumbs$: Observable<Breadcrumb[]>;
  title$: Observable<string>;
  showProfile = false;

  isMobile = true;

  get isAuthenticated(): boolean {
    return this.auth.isAuthenticated();
  }

  constructor(protected breadcrumbs: BreadcrumbService,
              protected auth: AuthService,
              protected translate: TranslateService,
              protected title: Title,
              protected bpo: BreakpointObserver) {
    this.bpo.observe([
      Breakpoints.XSmall,
      Breakpoints.Small
    ]).pipe(
      map(state => state.matches)
    ).subscribe(isMobile => this.isMobile = isMobile);
  }

  ngOnInit(): void {
    this.breadcrumbs$ = this.breadcrumbs.getBreadcrumbs().pipe(
      distinctUntilChanged(),
      map(bcs => bcs
        .filter(bc => !!bc)
        .map((bc, index, all) => ({
          ...bc,
          link: this.getLinkByIndex(all, index)
        }) as Breadcrumb)
      )
    );
    this.title$ = this.breadcrumbs.getCurrentTitle();

    this.breadcrumbs$.pipe(
      map(bcs => bcs.filter(bc => !!bc)),
      distinctUntilChanged(),
      map(bcs => bcs.map(bc => this.translate.get(bc.label))),
      distinctUntilChanged(),
      switchMap((bcs: Observable<string>[]) => forkJoin(bcs)),
      map(bcs => bcs.reverse()),
      map(bcs => bcs.join(' | '))
    ).subscribe(t => this.title.setTitle(t));
  }

  getLinkByIndex(all: Breadcrumb[], i: number): string {
    const link = [''];

    for (let h = 0; h <= i && h < all.length; h++) {
      link.push(all[h].link);
    }

    return link.join('/');
  }
}
