import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '@api/services/authentication.service';
import { catchError, map, mapTo, take, tap } from 'rxjs/operators';
import { AuthStorageService } from '@app/authentication/services/auth-storage.service';
import { ApiException, UserDto } from '@api/api.service';
import { Router } from '@angular/router';
import { RegisterDTO } from '@app/authentication/models/register';

export interface IAuthService {
  isAuthenticated(): boolean;

  authenticate(username: string, password: string, keepLoggedIn: boolean): Observable<boolean>;

  renew(): Observable<boolean>;

  logout(): Observable<boolean>;

  getCurrentUser(): UserDto;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements IAuthService {
  protected keepLoggedIn = false;

  constructor(private api: AuthenticationService,
              private storage: AuthStorageService,
              private router: Router) { }

  isAuthenticated(): boolean {
    return this.storage.getToken() !== null;
  }

  authenticate(username: string, password: string, keepLoggedIn: boolean): Observable<boolean> {
    this.keepLoggedIn = keepLoggedIn;
    return this.api.login({username, password}).pipe(
      map((res) => {
        if (res.success) {
          return res.data;
        }

        throw res;
      }),
      map(data => data.tokenData),
      tap(tokenData => this.storage.saveToken(tokenData, keepLoggedIn)),
      mapTo(true)
    );
  }

  renew(): Observable<boolean> {
    return this.api.renewToken(this.storage.getTokenForRenew()).pipe(
      map((res) => {
        if (res.success) {
          return res.data;
        }

        throw new Error(res.message);
      }),
      tap(tokenData => this.storage.saveToken(tokenData, this.keepLoggedIn)),
      catchError(() => this.logout()),
      mapTo(true)
    );
  }

  logout(): Observable<boolean> {
    return this.api.logout(this.storage.getTokenForLogout()).pipe(
      mapTo(true),
      catchError((err, caught) => {
        if (err.hasOwnProperty('success') || (err instanceof ApiException && err.status === 401)) {
          return of(true);
        } else {
          return of(false);
        }
      }),
      take(1),
      tap(async success => {
        if (success) {
          this.storage.clear();
          await this.router.navigate(['auth', 'login']);
        }
      })
    );
  }

  getCurrentUser(): UserDto {
    return this.storage.getCurrentUser();
  }

  register(dto: RegisterDTO): Observable<void> {
    return this.api.register(dto);
  }
}
