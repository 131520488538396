import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationModule } from '@core/navigation/navigation.module';
import { ThemingModule } from '@core/theming/theming.module';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { HomeComponent } from './components/home/home.component';
import { SharedModule } from '@shared/shared.module';


@NgModule({
  imports: [
    CommonModule,
    ThemingModule,
    NavigationModule,
    MatCardModule,
    TranslateModule,
    SharedModule
  ],
  exports: [
    NavigationModule,
    HomeComponent
  ],
  declarations: [HomeComponent]
})
export class CoreModule { }
