<mat-sidenav-container autosize class="sidenav-container">
  <mat-sidenav #drawer class="sidenav mat-elevation-z2"
               [mode]="isMobile ? 'over' : 'side'"
               [opened]="!isMobile"
               [disableClose]="!isMobile"
               [fixedInViewport]="!isMobile"
  >
    <app-menu (navigated)="isMobile ? drawer.close() : null"></app-menu>
  </mat-sidenav>
  <mat-sidenav-content id="content" cdkScrollable>
    <ng-container *ngIf="isAuthenticated">
      <mat-toolbar color="primary" [class.push]="!isMobile && drawer.opened">
        <mat-icon (click)="isMobile ? drawer.toggle() : null">menu</mat-icon>
        <h1 class="title" *ngIf="title$ | async as title">{{ title | translate }}</h1>
        <span class="spacer"></span>
        <button mat-icon-button (click)="showProfile = !showProfile">
          <mat-icon>account_box</mat-icon>
        </button>
      </mat-toolbar>
      <nav class="breadcrumbs" role="navigation" [class.push]="!isMobile && drawer.opened">
        <div class="breadcrumb">
          <mat-icon routerLink="/">home</mat-icon>
        </div>
        <ng-container *ngFor="let bc of breadcrumbs$ | async; let i = index">
          <div class="breadcrumb">
            <mat-icon>chevron_right</mat-icon>
            <span [routerLink]="bc?.link">{{ bc?.label | translate }}</span>
          </div>
        </ng-container>
      </nav>


      <div class="overlay" *ngIf="showProfile" (click)="showProfile = false"></div>
      <app-profile *ngIf="showProfile" (closed)="showProfile = false"></app-profile>
    </ng-container>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
