import { Injectable } from '@angular/core';
import { MenuItem } from '@core/navigation/models/menu-item';
import { CurrentUserService } from '@app/authentication/services/current-user.service';
import { UserType } from '@api/api.service';
import { CONTACT_MAIN_MENU_ITEMS, CUSTOMER_MAIN_MENU_ITEMS } from '@core/navigation/main-menu';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private currentUserService: CurrentUserService) {}

  getMainMenuItems(): Observable<MenuItem[]> {
    return this.currentUserService.get().pipe(
      map(user => {
        if (!user) {
          return [];
        } else if (user.userType === UserType.Customer) {
          return CUSTOMER_MAIN_MENU_ITEMS;
        } else {
          return CONTACT_MAIN_MENU_ITEMS;
        }
      })
    );
  }
}
