import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentUserInfoService } from '@api/services/current-user-info.service';
import { filter, map, take } from 'rxjs/operators';
import { UserType } from '@api/api.service';
import { CurrentUserService } from '@app/authentication/services/current-user.service';

@Injectable({
  providedIn: 'root'
})
export class IsCustomerGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private service: CurrentUserService,
              private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.isCustomer().pipe(
      map(isCustomer => {
        if (isCustomer) {
          return true;
        }

        return this.router.createUrlTree(['offers']);
      })
    );
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.isCustomer().pipe(
      map(isCustomer => {
        if (isCustomer) {
          return true;
        }

        return this.router.createUrlTree(['offers']);
      })
    );
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> {
    return this.isCustomer();
  }

  private isCustomer(): Observable<boolean> {
    return this.service.get().pipe(
      filter(info => !!info),
      take(1),
      map(info => info.userType === UserType.Customer)
    );
  }
}
