<app-error [type]="errorType"></app-error>

<mat-card *ngIf="false">
  <mat-card-header>
    <div mat-card-avatar class="avatar bg-warn">
      <mat-icon>info</mat-icon>
    </div>
    <mat-card-title>{{ 'APP.ERRORS.PAGE_NOT_FOUND.TITLE' | translate }}</mat-card-title>
    <mat-card-subtitle>{{ 'APP.ERRORS.PAGE_NOT_FOUND.MESSAGE' | translate }}</mat-card-subtitle>
  </mat-card-header>
</mat-card>
