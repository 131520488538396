import {
  ApiResponseOfContactOfferDto,
  FileResponse,
  IContactOfferApiService,
  OneTimeAttachmentInfoDto
} from '@api/api.service';
import { NEVER, Observable, of } from 'rxjs';
import { FILES } from '@app/mock/data/files';

export class ContactOfferApiMockService implements IContactOfferApiService {
  getContactOffer(): Observable<ApiResponseOfContactOfferDto> {
    return of({
      success: true,
      data: {
        welcomeMessage: 'Willkommen bei deinem Vorsorge-Portal! Hier kannst du deine Vorsorge-Verträge einsehen.',
        // tslint:disable:max-line-length
        introduction: `
          <p><b>This text is bold</b></p><p><i>This text is italic</i></p><p>This is<sub> subscript</sub> and <sup>superscript</sup></p>
          <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </p>
        `,
        // tslint:enable:max-line-length
        informationFiles: [
          {
            name: 'Gruppe 1',
            attachments: FILES
          },
          {
            name: 'Gruppe 2',
            attachments: FILES
          }
        ]
      }
    });
  }

  getOneTimeAttachmentId(attachmentId: string ): Observable<OneTimeAttachmentInfoDto> {
    return of({
      oneTimeId: attachmentId,
      validUntil: '2099-12-31T23:59:59Z'
    });
  }

  getAttachment(onetimeAttachmentId: string ): Observable<FileResponse> {
    return NEVER;
  }

}
