<mat-toolbar color="primary">
  <app-img-company type="secondary"></app-img-company>
</mat-toolbar>
<mat-nav-list>
  <mat-list-item
    *ngFor="let route of mainMenuItems$ | async"
    [routerLink]="route.path"
    (click)="navigated.emit()"
  >
    <mat-icon matListIcon>{{ route.icon | translate }}</mat-icon>
    <span>{{ route.label | translate }}</span>
  </mat-list-item>
</mat-nav-list>
<div class="legal-links mat-small mat-hint">
  <a routerLink="/auth/legal">{{ 'LEGAL_NOTICE.TITLE' | translate | uppercase }}</a>
  <a href="http://konermann.de/#datenschutz" target="_blank">{{ 'PRIVACY_POLICY.TITLE' | translate | uppercase }}</a>
</div>
