import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';
import { AuthService } from '@app/authentication/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class IsAuthenticatedGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private authService: AuthService,
              private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.isAuthenticated(next.url);
  }
  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.isAuthenticated(next.url);
  }
  canLoad(route: Route, segments: UrlSegment[]): boolean {
    return this.isAuthenticated(segments);
  }

  private isAuthenticated(url: UrlSegment[]): boolean {
    if (this.authService.isAuthenticated()) {
      return true;
    }


    this.router.navigate(['auth', 'login'], {
      queryParams: {
        redirectTo: url.join('/')
      }
    });

    return false;
  }
}
