import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ExtendedUserInfoDto, UserDto } from '@api/api.service';
import { AuthService } from '@app/authentication/services/auth.service';
import { CurrentUserInfoService } from '@api/services/current-user-info.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrentUserService } from '@app/authentication/services/current-user.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  userInfo$: Observable<ExtendedUserInfoDto>;

  @Output()
  closed = new EventEmitter();

  logoutError = false;
  logoutPending = false;

  constructor(private authService: AuthService,
              private userInfoService: CurrentUserService) { }

  ngOnInit() {
    this.userInfo$ = this.userInfoService.get();
  }

  logout() {
    this.logoutPending = true;
    this.authService.logout().subscribe(
      (success) => {
        this.logoutPending = false;
        if (success) {
          this.closed.emit();
        } else {
          this.logoutError = true;
        }
      },
      () => {
        this.logoutPending = false;
        this.logoutError = true;
      }
    );
  }
}
