import {Injectable} from '@angular/core';
import {ColorPalette} from '../models/color-palette';
import * as chroma from 'chroma-js';

@Injectable()
export class ThemeService {

  constructor() {}

  public setTheme(primary: string, primaryContrast: string, accent: string, accentContrast: string): void {
    this.setColorPalette('primary', this.generatePalette(primary, primaryContrast));
    this.setColorPalette('accent', this.generatePalette(accent, accentContrast));
  }

  private generatePalette(color: string, contrast: string): ColorPalette {
    const baseColor = chroma(color);
    const colorClasses = chroma.scale([
      baseColor.brighten(2.5),
      baseColor,
      baseColor.darken(1),
    ]).domain([0, 1000]).classes(10);

    const baseContrast = chroma(contrast);
    const contrastClasses = chroma.scale([
      baseContrast.darken(2.5),
      baseContrast,
      baseContrast.brighten(1),
    ]).domain([0, 1000]).classes(10);

    return {
      50: colorClasses(50).hex(),
      100: colorClasses(100).hex(),
      200: colorClasses(200).hex(),
      300: colorClasses(300).hex(),
      400: colorClasses(400).hex(),
      500: baseColor.hex(),
      600: colorClasses(600).hex(),
      700: colorClasses(700).hex(),
      800: colorClasses(800).hex(),
      900: colorClasses(900).hex(),
      A100: baseColor.saturate(2).brighten(1).hex(),
      A200: baseColor.saturate(4).hex(),
      A400: baseColor.saturate(8).darken(1).hex(),
      A700: baseColor.saturate(10).darken(2).hex(),
      contrast: {
        50: contrastClasses(50).hex(),
        100: contrastClasses(100).hex(),
        200: contrastClasses(200).hex(),
        300: contrastClasses(300).hex(),
        400: contrastClasses(400).hex(),
        500: baseContrast.hex(),
        600: contrastClasses(600).hex(),
        700: contrastClasses(700).hex(),
        800: contrastClasses(800).hex(),
        900: contrastClasses(900).hex(),
        A100: contrastClasses(100).hex(),
        A200: contrastClasses(400).hex(),
        A400: contrastClasses(600).hex(),
        A700: contrastClasses(900).hex()
      }
    };
  }

  private setColorPalette(name: string, palette: ColorPalette): void {
    for (const key of Object.keys(palette)) {
      if (key === 'contrast') {
        for (const nKey of Object.keys(palette[key])) {
          this.setVariable('--theme-' + name + '-contrast-' + nKey, palette[key][nKey]);
        }

        continue;
      }
      this.setVariable('--theme-' + name + '-' + key, palette[key]);
    }
  }

  private setVariable(key: string, value: string): void {
    document.documentElement.style.setProperty(key, value);
  }
}
