import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthStorageService } from '@app/authentication/services/auth-storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authStorage: AuthStorageService) {
  }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next.handle(this.setAuthorizationHeader(req));
  }

  private setAuthorizationHeader<T>(req: HttpRequest<T>): HttpRequest<T> {
    if (req.url.includes('/api') && !req.url.includes('Auth/login')) {
      const authToken = this.authStorage.getTokenForApi();

      return req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + authToken)
      });
    }

    return req;

  }
}
