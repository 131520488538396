import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsAuthenticatedGuard } from '@app/authentication/guards/is-authenticated.guard';
import { NavigationComponent } from '@core/navigation/navigation/navigation.component';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';
import { IsContactGuard } from '@app/authentication/guards/is-contact.guard';
import { IsCustomerGuard } from '@app/authentication/guards/is-customer.guard';
import { HomeComponent } from '@core/components/home/home.component';


const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: '',
    component: NavigationComponent,
    canActivate: [IsAuthenticatedGuard],
    canActivateChild: [IsAuthenticatedGuard],
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'customer',
        loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule),
        canLoad: [IsCustomerGuard],
        canActivate: [IsCustomerGuard]
      },
      {
        path: 'contacts',
        loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)
      },
      {
        path: 'offers',
        loadChildren: () => import('./offer/offer.module').then(m => m.OfferModule),
        canActivate: [IsContactGuard]
      },
      {
        path: 'news',
        loadChildren: () => import('./news/news.module').then(m => m.NewsModule)
      },
      {
        path: 'contracts',
        loadChildren: () => import('./contract/contract.module').then(m => m.ContractModule)
      },
      {
        path: 'about',
        loadChildren: () => import('./about/about.module').then(m => m.AboutModule)
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'offers'
      },
      {
        path: '**',
        component: NotFoundComponent
      }
    ]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth/login'
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    relativeLinkResolution: 'corrected'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
